import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.svg'
import github from '../img/logos/github.svg'
import gitlab from '../img/logos/gitlab.svg'
import telegram from '../img/social/telegram.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Kaldi"
            style={{ width: '14em', height: '10em' }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <a
                        className="navbar-item"
                        href="https://gccms.netlify.app/admin/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Admin
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/blog">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a title="github" href="https://github.com/garrettcorn">
                  <img
                    src={github}
                    alt="github"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="gitlab" href="https://gitlab.com/garrettcornwell">
                  <img
                    className="fas fa-lg"
                    src={gitlab}
                    alt="gitlab"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="telegram" href="https://t.me/garrettcornwell">
                  <img
                    src={telegram}
                    alt="telegram"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
